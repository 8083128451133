import React, { Component } from 'react';
import './App.css';
//import {Button} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
import MyFooter from './Pages/Test/components/MyFooter';
//import CarouselBox from './Components/CarouselBox';
import Head1 from './Components/Head1';

//function App() {
class App extends Component {
  render () {
  return (
    <div className="App">
      <Header />
      {/*<CarouselBox />*/}
      <MyFooter />
    </div>
  );
  }}

export default App;
