import React from "react"

// Когда тег был textarea, то данные обновлялись
class MyTextArea extends React.Component {
    render () {
        return (
            <div style={{'text-align': 'left'}}>
                <p className="mytextarea">{this.props.txt}</p>
            </div>
        )
    }
}

export default MyTextArea