import React from "react"
//import MyButtonArr from "./components/MyButtonArr"
//import MyLabelArr from "./components/MyLabelArr"
//import MyButton from "./components/MyButton"
import MyLabel from "./components/MyLabel"
import MyTextArea from "./components/MyTextArea"
import "./css/main.css"
//import Test1 from "./tests/Test1"
import MyFooter from "./components/MyFooter"
import MyNav from "./components/MyNav"
//import Test2 from "./tests/Test2"
import MyButtonF from "./components/MyButtonF"
import { Container, Row, Col } from "react-bootstrap"

const f1 = "/txt/test3_unicode.txt"  // Файл с вопросами/ответами
const delimiter = '\t#\t'            // Разделитель между столбцами

class Memorization extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            qa : [
                {
                    id : 1,
                    q : "question 1",
                    a : "answer1"
                },
                {
                    id : 2,
                    q : "question 2",
                    a : "Right_answer2"
                }
            ],
            iq : 0, // индекс слов вопросов 
            ia : 0, // индекс правильных слов ответов
            ic : 0,  // индекс букв в правильном слове
            rw : "",    // угадываемое слово
            rc : "",    // текущая правильная буква
            //arrLabel : this.addLabel(this.state.qa[0]),   // Массив лейбл
            arrLabel : this.addLabel("answer1"),   // Массив лейбл
            //arrLabel : [],   // Массив лейбл
            txtQ : "question 1",  // Для хранения текущего вопроса
            arrButton : this.addButton("answer1"),  // Для хранения кнопок
            arrCharBt : [],      // Массив для хранения случайно отсортированного текущего порядка букв
            selector : 0,        // Селектор вида страницы
            statusBtF : 0,      // Статус кнопки перехода дальше (к след слову). 0 - неактивна, 1 - активна
            timerID : 0,        // Храниит ID запущенного таймера
            nameTest : "",       // Наименование теста
            allQ : 0,           // Всего вопросов в тесте

            //var f1 = '/txt/text.txt'
            //f1 : "/txt/test3_unicode.txt",  // Файл с вопросами/ответами
            //delimiter : '\t#\t',            // Разделитель между столбцами
            idt : 0,                        // индекс тем вопросов
            arrAll : this.readData(),          // прочитаем файл, преобразуем в массив тем/вопросов/ответов
            arrAll1 : [],          // здесь храним /вопросов/ответов по одной текущей теме
            //arrTeam : this.readTeam(),      // здесь храним только имена тем
            arrTeam : [],      // Нужно: 1- наименование тем; 2- id каждой темы; 3- количество вопросов по каждой теме
            arrTeam1 : [],      // здесь храним только вопросы/ответы по текущей теме
        }

        // Укажем здесь наши методы иначе из них нельзя будет менять наши состояния (переменные)
        this.onClickButton = this.onClickButton.bind(this)
        this.addLabel = this.addLabel.bind(this)
        this.editQuestion = this.editQuestion.bind(this)
        this.addButton = this.addButton.bind(this)
        this.updateLabel = this.updateLabel.bind(this)
        this.updateButton = this.updateButton.bind(this)
        this.onClickStart = this.onClickStart.bind(this)
        this.onClickHref = this.onClickHref.bind(this)
        this.onClickMyButtonF = this.onClickMyButtonF.bind(this)
        this.readData = this.readData.bind(this)
        this.csvToObjs = this.csvToObjs.bind(this)
        this.queryTeamQA = this.queryTeamQA.bind(this)
    }

    LabelArr = (props) => {
        this.setState({arrLabel : this.addLabel(props)})
        return(
            <div>
                {this.state.arrLabel}
            </div>
        )
    }

    //var welcome = 0

    // <MyLabelArr str={this.state.qa[0]}/>
    // <MyTextArea txt={this.state.txtQ}/>
    // <MyButtonArr str={this.state.qa[0]} onClick={this.onClickButton}/>
    // !!! Если здесь поместить вызов функции - то будет постоянный ее вызов
    render () {
        if (this.state.selector === 0) {
            console.log(`selector: ${this.state.selector}`)
            return (
                <>
                <Container fluid className={{'height': '1200px'}}>
                    <Row>
                        <Col md="3">
                    <MyNav onClickHR={this.onClickHref} team={this.state.arrTeam}/>
                    {/*<main>*/}
                    </Col>

                    <Col md="9">
                    <div>
                        <h1>Приглашаем ответить на вопросы по швейной тематике</h1>
                        <button className="mybutton" onClick={this.onClickStart}>Начать</button>
                    </div>
                    {/*</main>*/}
                    {/*<MyFooter />*/}
                    </Col>
                    </Row>
                </Container>
                </>
            )
        }    
        else {
            console.log(`selector: ${this.state.selector}`)
            return (
                <Container fluid>
                    <Row>
                        <Col md="3">
                    <MyNav onClickHR={this.onClickHref} team={this.state.arrTeam}/>
                    </Col>
                    
                    <Col md="9">
                    {/*<main>*/}
                    <div>
                        <p className="numberquestion">вопрос {this.state.iq+1}/всего {this.state.allQ}</p>
                        <h1>{this.state.nameTest}</h1>
                        <MyTextArea txt={this.state.txtQ} />
                        <div>{this.state.arrLabel}</div>
                        <div>{this.state.arrButton}</div>
                        
                        <MyButtonF statusBtF={this.state.statusBtF} onClickBtF={this.onClickMyButtonF}/>
                    </div>
                    {/*</main>*/}
                    {/*<MyFooter />*/}
                    </Col>
                    </Row>
                </Container>
            )
        }
    }

    updateMyNav() {
        var arrteam = ['Test1', 'Test2']
        var mynav = document.querySelector(".mynav")
        var strnav = ''
        //arrteam.forEach(a => an.push(`<p><a className="mynavitem">${a}</a></p>`))
        arrteam.forEach(a => {strnav += `<p><a className="mynavitem" onClick=${console.log('click nav')}>${a}</a></p>`})
        console.log('strnav')
        console.log(strnav)
        mynav.innerHTML = strnav
    }

    // Узнаем наименование темы id теста, перечень вопросов/ответов по этой теме (id - счет с 1)
    queryTeamQA(id) {
        let arr = this.state.arrAll
        let arrTQAid = arr.filter(arr1 => arr1.idt === id.toString())    // Сохраним все вопросы с темой id
        console.log('arrTQAid[0].t, arrTQAid')
        //console.log(arrTQAid[0].t +'\n' + arrTQAid)
        console.log(arrTQAid)
        return [arrTQAid[0].t, arrTQAid]    // Вернем имя темы, весь массив Тема/впоросы/ответы
    }

    // Обработка нажатия на кнопку Начать тест
    onClickStart() {
        // Нужно установить наименование теста
        // Нужно наименование темы первого теста, перечень вопросов/ответов по этой теме
        //let [nameT, arrq] = Test1()
        let [nameT, arrq] = this.queryTeamQA(1) // Узнаем наименование темы первого теста, перечень вопросов/ответов по этой теме
        this.setState({allQ : arrq.length})     // Всего вопросов в тесте
        this.setState({txtQ : arrq[0].q})       // Для хранения текущего вопроса
        this.setState({nameTest : (nameT)})     // Наименование текущего теста
        this.setState({qa : []}, ()=>{this.setState({qa : [...arrq]})}) // Для хранения текущего id/вопрос/ответ
        this.setState({selector : 1})
        // Отобразить новые лейбы и кнопки
        this.updateLabel(arrq[0].a)     // лейбы первого слова
        this.updateButton(arrq[0].a)    // кнопки первого слова
       
        // Обновим навигатор
        //this.updateMyNav()
    }

    // Обработка навигации
    onClickHref(id) {
        //let nameT = ""
        //var arrq = []
        // Нужно по id Узнаем наименование темы id теста, перечень вопросов/ответов по этой теме (id - счет с 1)
        if (id !== 0) {
        let [nameT, arrq] = this.queryTeamQA(Number(id))
        //switch (id) {
        //    case 1 :
        //        [nameT, arrq] = Test1()
                //this.setState({selector : id})
        //        break
        //    case 2 :
        //        [nameT, arrq] = Test2()
                //this.setState({selector : id})
        //        break
        //    default :
        //        [nameT, arrq] = Test1()
                //this.setState({selector : id})
        //}
        // Установим наименование теста
        this.setState({nameTest : (nameT)})
        this.setState({allQ : arrq.length})
        // Установим массив вопросов/ответов, селектор
        this.setState({txtQ : arrq[0].q})
        this.setState({qa : []}, ()=>{this.setState({qa : [...arrq]})})
        // Отобразить новые лейбы и кнопки
        this.updateLabel(arrq[0].a)
        this.updateButton(arrq[0].a)
        }

        this.setState({selector : Number(id)})
        // Сброс все индексов
        this.setState({iq : 0})
        this.setState({ia : 0})
        this.setState({ic : 0})
        // Отобразить новые лейбы и кнопки
        //this.updateLabel(arrq[0].a)
        //this.updateButton(arrq[0].a)
       
    }

    // Обработчик нажатий на кнопки
    // id - индентификатор кнопки (индекс)
    // ch - символ кнопки (буква)
    onClickButton(id, ch) {
        //console.log('Нажал на кнопку id: {$id}, буква: {$ch}')
        console.log(`Нажал на кнопку id: ${id}, буква: ${ch}`)

        this.setState({rw : this.state.qa[this.state.ia].a})   // Получим текущее угадываемое слово
        let rw = this.state.qa[this.state.ia].a
        //console.log('Слово: ' + this.state.rw)
        console.log('Слово: ' + rw)

        this.setState({rc : this.state.rw[this.state.ic]})                 // Получим текущий символ
        let rc = rw[this.state.ic]
        //console.log('Текущий символ: ' + this.state.rc)
        console.log('Текущий символ: ' + rc)

        // Проведем сравнение текущего символа и символа нажатой кнопки
        //if (ch === this.state.rc) {
        if (ch === rc) {
            // Отбразим символ на лейбе с индексом ic
            console.log('ic: ' + this.state.ic)
            //MyLabelArr.editLabel()  // ERROR !!!
            this.editLabel(ch, this.state.ic)

            // Заблокровать нажатую кнопку
            this.disableButton(id, ch)

            // Перейдем к следующей букве
            this.setState({ic : this.state.ic + 1})
            let ic = this.state.ic + 1

            // Проверим если вышли за длину слова -------------- угадали слово
            //if (this.state.ic >= this.state.rw.length) {
            //if (ic >= this.state.rw.length) {
            if (ic >= rw.length) {
                // Выдать сообщение на несколько секунд - Правильно !
                console.log('Правильно ! - ' + this.state.rw)
                // Приостановить работу программы - Запустить таймер
                //this.sleepF(3000)

                // Перейдем к следующему слову
                this.setState({iq : this.state.iq + 1})
                let iq = this.state.iq + 1
                //console.log('iq: ' + this.state.iq)
                console.log('iq: ' + iq)
                // Перейдем к следующему правльному ответу
                this.setState({ia : this.state.ia + 1}) 
                this.setState({ic : 0})  

                // Проверим - закончились вопросы ?
                //if (this.state.iq >= this.state.qa.length) {
                if (iq >= this.state.qa.length) {
                    // Выдать сообщение - Тест пройден !
                    console.log('Тест пройден !')

                    // Сбросим все счетчики
                    this.setState({iq : 0}); iq = 0
                    this.setState({ia : 0})
                    this.setState({ic : 0})
                    console.log('Начнем заново')
                    // Отобразим вопрос
                    //console.log('Вопрос: ' + this.state.qa[this.state.iq])
                    console.log('Вопрос: ' + this.state.qa[iq])
                }
                // Если вопросы не закончились - перейдем к следующему
                else {
                    // Активируем кнопку перехода
                    this.setState({statusBtF : 1})
                    // Приостановим переход к другому слову
                    this.setState({
                    timerID : setTimeout(()=> {
                    //this.setState({iq : this.state.iq + 1})
                    //this.setState({ia : this.state.ia + 1}) // Перейдем к следующему правльному ответу
                    //this.setState({ic : 0})                 // Индекс текущего символа в правильном слове установим в начало
                    
                    // Отобразим следующий вопрос
                    this.editQuestion(this.state.qa[iq].q)
                    //console.log('Вопрос: ' + this.state.qa[this.state.iq])
                    console.log('Вопрос: ' + this.state.txtQ)

                    // Отобразить новые лейбы и кнопки
                    this.updateLabel(this.state.qa[iq].a)
                    this.updateButton(this.state.qa[iq].a)

                    // ДеАктивируем кнопку перехода
                    this.setState({statusBtF : 0})

                    }, 5000)
                    })
                    console.log(`timerID: ${this.state.timerID}`)
                }
            }
        }
    }

    // Добавляем лейбы
    addLabel(str) {
        //let str1 = str.a
        //console.log(str1)
        //let arr = str1.split('')
        let arr = str.split('')
        let arrOut = []
        let id = 0
        arr.forEach(element => {
            arrOut.push(<MyLabel ch={''} key={id}/>)
            id += 1
        });
        return arrOut
    }

    // Меняем лейбы
    editLabel(c, i) {
        console.log('Edit label, c: ' + c + ', i: ' + i)
        let al = this.state.arrLabel
        al[i] = <MyLabel ch={c} key={i}/>
        this.setState({arrLabel : []}, ()=>{this.setState({arrLabel : [...al]})})
    }

    // Обновим лейбы
    updateLabel(a) {
        console.log('updateLabel, a: ' + a)
        let al = this.addLabel(a)
        this.setState({arrLabel : []}, ()=>{this.setState({arrLabel : [...al]})})
    }

    // Меняем вопрос
    editQuestion(q) {
        console.log('Установим вопрос: ' + q)
        this.setState({txtQ : q})
    }

    // Случайная сортировка массива данных
    rndButton(str) {
        let arr = str.split('')
        let arrRndSort = arr
        .map(function(e,i) {return [e, Math.random()]})
        .sort(function(a,b) {return a[1]-b[1]})
        .map(function(e) {return e[0]})
        return arrRndSort
    }

    // Добавляем кнопки
    addButton(str) {
        //let arr = str.split('')
        let arr = this.rndButton(str)
        this.setState({arrCharBt : []}, ()=>{this.setState({arrCharBt : [...arr]})})
        let arrOut = []
        var i = 0
        arr.forEach(element => {
            const ibt = i
            //arrOut.push(<MyButton dsbl={"false"} ch={element} onClick={this.props.onClick} key={element.id}/>)
            //arrOut.push(<MyButton ch={element}/>)
            //arrOut.push(<div><button className="mybutton" onClick={()=>this.onClickButton(1,element)}>{element}</button></div>)
            arrOut.push(<button className="mybutton" onClick={()=>this.onClickButton(ibt ,element)} key={i}>{element}</button>)
            i += 1
        });
        return arrOut
    }

    // Блокируем кнопки
    disableButton(i, ch) {
        console.log(`disableButton, ch: ${ch}, i: ${i}`)
        let ab = this.state.arrButton
        ab[i] = <button className="mybuttondisabled" onClick={()=>this.onClickButton(i,ch)} disabled>{ch}</button>
        this.setState({arrButton : []}, ()=>{this.setState({arrButton : [...ab]})})
    }

    // Обновим кнопки
    updateButton(a) {
        console.log('updateButton, a: ' + a)
        let ab = this.addButton(a)
        this.setState({arrButton : []}, ()=>{this.setState({arrButton : [...ab]})})
    }

    // Обработка нажатие на кнопку Продолжить
    onClickMyButtonF(i) {
        //this.setState({iq : this.state.iq + 1})

        console.log(`onClickMyButtonF, timerID: ${this.state.timerID}`)
        clearTimeout(this.state.timerID)

        // Отобразим следующий вопрос
        this.editQuestion(this.state.qa[this.state.iq].q)
        //console.log('Вопрос: ' + this.state.qa[this.state.iq])
        console.log('Вопрос: ' + this.state.txtQ)

        // Отобразить новые лейбы и кнопки
        this.updateLabel(this.state.qa[this.state.iq].a)
        this.updateButton(this.state.qa[this.state.iq].a)

        // ДеАктивируем кнопку перехода
        this.setState({statusBtF : 0})
    }

    // Преобразуем данные из csv в массив данных
    csvToObjs(data) {
        const lines = data.split(/\r\n|\n/);
        //console.log(`lines\n${lines}, length: ${lines.length}`)
        let [headings, ...entries] = lines;
        //headings = headings.split(this.state.delimiter);
        headings = headings.split(delimiter);
        //console.log(`headings\n${headings}`)
        const objs = [];
        entries.map(entry => {
            //const obj = entry.split(this.state.delimiter);
            const obj = entry.split(delimiter);
            //console.log(`obj\n${obj}`)
            objs.push(Object.fromEntries(headings.map((head, i)=>[head, obj[i]])));
            //console.log(`objs\n${objs}`)
        })
        return objs;
    }

    // Читаем данные из файла и сформируем полный массив данных
    readData() {
        // read text from URL location
        var arr = []
        //var arrf = []
        var request = new XMLHttpRequest();
        //request.open('GET', this.state.f1, true);
        request.open('GET', f1, true);
        request.send(null);
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            var type = request.getResponseHeader('Content-Type');
            if (type.indexOf("text") !== 1) {
                //return request.responseText;
                console.log("Прочитаем текстовый файл\n")
                //console.log(request.responseText)
                console.log("Создадим массив")
                //console.log(csvParser(request.responseText))
                //console.log(csvParser("Test1;Q1;A1"))
                console.log("csvToObjs")
                arr = this.csvToObjs(request.responseText)
                //console.log(csvToObjs(request.responseText))
                console.log(`arr`)
                console.log(arr)

                // Выведем индексы тем
                //var i = 0
                //for (i=0; i< arr.length; i++) {
                //    console.log(`idt = ${arr[i].idt}`)
                //}

                // Нужно: 1- наименование тем; 2- id каждой темы; 3- количество вопросов по каждой теме

                let arrq1 = arr.filter(arr1 => arr1.idq === '1')    // Сохраним все вопросы с индексом 1
                let arrT = []                       // Сохраним только имена тем
                arrq1.forEach(a => arrT.push(a.t))  // Сохраним только имена тем
                console.log('arrT')
                console.log(arrT)

                let arridt = []                       // Сохраним только idt тем
                arrq1.forEach(a => arridt.push(a.idt))  // Сохраним только idt тем
                console.log('arridt')
                console.log(arridt)

                let arrNqinT = []       // Определим количество вопросов по каждой теме
                arridt.forEach(i => (arrNqinT.push((arr.filter(arr1 => arr1.idt === i)).length)))
                console.log('arrNqinT')
                console.log(arrNqinT)

                // Объединим все вместе
                var arrTAll =[]
                //var arrTAll1 = {t:'', id:0, n:0}
                var i = 0
                for (i = 0; i < arrT.length; i++) {
                    let arrTAll1 = {t:'', id:0, n:0}
                    arrTAll1.t = arrT[i]
                    arrTAll1.id = arridt[i]
                    arrTAll1.n = arrNqinT[i]
                    arrTAll.push(arrTAll1)
                    console.log(arrTAll1)
                }
                console.log(arrTAll)
                //arrTAll.forEach(item => console.log(item))

                //arr.forEach(a => arrT.push((a.filter(a1 => a1.idq === '1'))).t)
                this.setState({arrTeam : arrTAll})
                console.log('arrT')
                console.log(arrT)
                console.log('arr')
                console.log(arr)
                this.setState({arrAll: arr})
                return arr
                }
            }
        }
    }
}

export default Memorization