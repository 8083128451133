import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import c2_1 from '../Img/Course/c2_1.jpg'
import c2_2 from '../Img/Course/c2_2.jpg'
import c2_3 from '../Img/Course/c2_3.jpg'
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
  .bt {
    background: #ffffff;
    font-size: 14pt;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;       
  }
`


//function App() {
export default class Course2 extends Component {
  render () {
    return (
      <Styles>
        <Container style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          <Row>
            <Col md="2">
              <Row style={{paddingTop: "3rem"}}>

              <img
                  variant="left"
                   width={140}
                  height={130} 
                  src={c2_1} 
                  className="mr-3"
                />

              </Row>
              <Row style={{paddingTop: "3rem"}}>

                <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={140}
                  height={200} 
                  src={c2_2} 
                  className="mr-3"
                />

              </Row>

              <Row style={{paddingTop: "3rem"}}>

                <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={140}
                  height={200} 
                  src={c2_3} 
                  className="mr-3"
                />

              </Row>
                
            </Col>
            <Col md="9" style={{color: '#2a2a2a'}}>
              <h3><em>Скоро начало! (срок 6 месяцев)</em></h3>
                  <h2>Запись на Курс обучению основам конструирования и пошива одежды с нуля</h2>
                  <h4><u><em><strong>Что изучаем?</strong></em></u></h4>
                  <ul style={{'text-align': "left", 'line-height': '2', 'font-size': '15pt'}}>
                    <li>классификацию одежды, силуэты, прибавки, объемы одежды;</li>
                    <li>измерение фигуры, размер, полнотные группы, типы телосложений;</li>
                    <li>построение основы плечевого изделия с учетом индивидуальных особенностей;</li>
                    <li>выполняем макет малого объёма полуприлегающего силуэта;</li>
                    <li>примеряем макет, вносим изменения в конструкцию;</li>
                    <li>моделируем основу для получения платья, кроим и отшиваем его.</li>
                    <li>Изучаем некоторые виды воротников и рукавов.</li>
                    <li>Строим основу плечевого изделия умеренного, большого объема, кроим и отшиваем изделия (футболку, толстовку, жакет).</li>
                    <li>Поясные изделия. Виды. Размерные признаки для поясных изделий.</li>
                    <li>Построение и пошив 1 вида юбки и брюк.</li>
                  </ul>
                  <h5>Количество готовых изделий: 8 - 10!</h5>
                  <h5><em>По окончанию курса выдается сертификат для вашего портфолио, подтверждающий обучение.</em></h5>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bt'>
        <Col md="4">
            <PsevdoButton txt='Узнать подробней, записаться на курсы' />
        </Col>
        <Col md="4">
            <PsevdoButton_Tlg txt='Узнать подробней, записаться на курсы' />
        </Col>
      </Container>

      </Styles>
    );
  }
}

