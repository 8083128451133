import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import mc from '../Img/Assets/as2_m.jpg'
import mc1 from '../Img/MasterClass/mc1.jpg'
import mc2 from '../Img/MasterClass/mc2.jpg'
import mc3 from '../Img/MasterClass/mc3.jpg'
import mc4 from '../Img/MasterClass/mc4.jpg'
import mc5 from '../Img/MasterClass/mc5.jpg'
import styled from 'styled-components';
import PsevdoButton from './PsevdoButton';
import PsevdoButton_Tlg from './PsevdoButton_Tlg';

const Styles = styled.div`
    .imgmc {
        padding-top: 2rem;
        background-image: url(${mc});
        height: 400px;
    }
    .descr {
        background: #ffff80;
        font-size: 18pt;
        padding-bottom: 10px;
    }
    .mc1 {
        background: #ffffff;
        font-size: 14pt;
        display: flex;
        justify-content: space-around;       
    }
    .mc2 {
        background: #ffff80;
        font-size: 14pt;
        display: flex;
        justify-content: space-around;
    }
    .mc1col {
        display: flex;
        justify-content: center;
    }
    .mc2col {
        display: flex;
        justify-content: center;
    }
    .mc1card {
        width: 20rem;
        border-color: transparent;
        background: #ffffff
    }
    .mc2card {
        width: 20rem;
        border-color: transparent;
        background: #ffff80
    }
    .bt {
        background: #ffffff;
        font-size: 14pt;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;       
    }
    .row {
        display: flex;
        justify-content: space-around;
    }
`
export default class MasterClass extends Component {
  render () {
    return (
        <>
        <Styles>
        <Container fluid className="imgmc">
                <h1 style={{'text-transform': 'uppercase', paddingTop: "8rem"}}>Мастер-классы</h1>
        </Container>

        <Container fluid className="descr">
            <p>На мастер-класс можно прийти с любым опытом пошива одежды.</p>
            <p>По желанию предоставляются необходимые материалы.</p>
            <p><em>На мастер-классы записываемся заранее!</em></p>
            <PsevdoButton txt='Задать вопросы, записаться на мастер-класс' />
        </Container>

        <Container fluid className='mc1'>
            <Row className='row'>
            <Col md="3" className='mc1col'>
                <Card className='mc1card'>
                <Card.Img variant='top' src={mc1} width={300} height={250} />
                <Card.Body><Card.Text>
                <p>По построению и пошиву классических трусиков</p></Card.Text></Card.Body>
                </Card>
            </Col>

            <Col md="3" className='mc1col'>
            <Card className='mc1card'>
                <Card.Img variant='top' src={mc5} width={300} height={250} />
                <Card.Body><Card.Text>
                <p>По построению и пошиву трусиков "Бразильяна"</p></Card.Text></Card.Body>
                </Card>
            </Col>

            <Col md="3" className='mc1col'>
            <Card className='mc1card'>
                <Card.Img variant='top' src={mc2} width={300} height={250} />
                <Card.Body><Card.Text>
                <p>По построению и пошиву Бралетт</p></Card.Text></Card.Body>
                </Card>
            </Col>
            </Row>
        </Container>

        <Container fluid className='mc2'>
            <Row className='row'>
        <Col md="5" className='mc2col'>
            <Card className='mc2card'>
                <Card.Img variant='top' src={mc3} width={350} height={250} />
                <Card.Body><Card.Text>
                    {/*<p>В СЕНТЯБРЕ</p>*/}
                    <p>МК по пошиву бюстгальтера на косточках</p>
                    <p>Продолжительность -  8 часов.</p>
                </Card.Text></Card.Body>
            </Card>
        </Col>

        <Col md="5" className='mc2col'>
            <Card className='mc2card'>
                <Card.Img variant='top' src={mc4} width={200} height={380} />
                <Card.Body><Card.Text>
                    {/*<p>В АВГУСТЕ</p>*/}
                    <p>МК по построению и пошиву брюк</p>
                    <p>Продолжительность - 20 часов.</p>
                    {/*<p>7000 рублей</p>*/}
                </Card.Text></Card.Body>
            </Card>
        </Col>
        </Row>
        </Container>

        <Container fluid className='bt'>
            <Col md="6">
                <PsevdoButton_Tlg txt='Задать вопросы, записаться на мастер-класс' />
            </Col>
        </Container>

        </Styles>
        </>

    );
}
}
