import React, { Component } from "react";
import { Button, Container, FormControl, Nav, Navbar, Form } from "react-bootstrap";
import { Route, BrowserRouter, Routes, Link, Outlet } from "react-router-dom";
import logo_bsh from '../Img/logo_bsh.jpg';
import Home from "../Pages/Home";
import Course from "../Pages/Course";
import Course1 from "../Pages/Course1";
import Course2 from "../Pages/Course2";
import Course3 from "../Pages/Course3";
import Contact from "../Pages/Contact";
import Memorization from "../Pages/Test/Memorization";
import styled from 'styled-components'
import MasterClass from "./MasterClass";
import Schedule from "../Pages/Schedule";
import SignUp from "../Pages/SignUp";

const Styles = styled.div `
    a, .navbar-brand, .navbar-nav, .navbar-link {
        color : # adb1b8;
        font-size: 12pt;
        &:hover {
            color: red;
        }
    }
`

export default class Header extends Component {
    render () {
        return (
            <>
                <Styles>
                    

            <Navbar fixed="top" collapseOnSelect expand="md" bg="warning"> {/*   variant="dark"*/}
                

                <Container fluid>
                    <Navbar.Brand href="/">
                        <img
                            src={logo_bsh} 
                            height="50"
                            width="50"
                            className="d-inline-block align-top"
                            alt="logo"
                        /> <span style={{'vertical-align': '-15px'}}>Школа шитья Благовещенск</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
                        <Nav className="mr-auto">
                            <Nav.Link href="/">Главная</Nav.Link>
                            <Nav.Link href="/course">Курсы</Nav.Link>
                            <Nav.Link href="/masterclasses">Мастер-классы</Nav.Link>
                            <Nav.Link href="/schedule">Расписание</Nav.Link>                            
                            <Nav.Link href="/signup">Записаться</Nav.Link>
                            <Nav.Link href="/memorization"><em>Запоминалка!</em></Nav.Link>
                            <Nav.Link href="/contact">Контакты</Nav.Link>
                        </Nav>
                        {/*
                        <Form className="d-flex">
                            <FormControl 
                                type="text"
                                placeholder="Search"
                                className="mr-sm-2"
                            />
                            <Button variant="outline-info">Поиск</Button>
                        </Form>
        */}
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            </Styles>
            <Outlet/>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />}/>
                    <Route path="course" element={<Course />}/>
                    <Route path="course1" element={<Course1 />}/>
                    <Route path="course2" element={<Course2 />}/>
                    <Route path="course3" element={<Course3 />}/>
                    <Route path="masterclasses" element={<MasterClass />}/>
                    <Route path="memorization" element={<Memorization />}/>
                    <Route path="schedule" element={<Schedule />}/>
                    <Route path="contact" element={<Contact />}/>
                    <Route path="signup" element={<SignUp/>}/>
                </Routes>
            </BrowserRouter>
            </>
        )
    }
} 