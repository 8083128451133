import React, { Component } from 'react';
import { Container, Nav, Row, Tab, Col } from 'react-bootstrap';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';
import foto from '../Img/Contact/foto.jpg'
import map from '../Img/Contact/map.jpg'

//const hrefToGis = "https://www.google.com/url?q=https%3A%2F%2Fgo.2gis.com%2Frdl4o&amp;sa=D&amp;sntz=1&amp;usg=AOvVaw3OpbTv7Lirg-MFMl6Al6l8"
const hrefToGis = "https://go.2gis.com/jl27x"
//const hrefToGis = "https://go.2gis.com/xgs52l"

//function App() {
  // https://go.2gis.com/

export default class Contact extends Component {
  render () {
    return (
        <Container fluid style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          <Row>
            <Col md="3">
            <img
                  variant="left"
                   width={250}
                  height={270} 
                  src={foto} 
                  className="mr-3"
                />

            </Col>
            <Col md="9">
              <h4 style={{'text-align': "left", 'color': '#000'}}>Занятия ведёт</h4>
              <h4 style={{'text-align': "left", 'color': '#c23416'}}><em>Горковенко Юлия Валерьевна</em></h4>
              <p style={{'text-align': "left", 'color': '#000', 'font-size': '14pt'}}>профессиональный педагог с опытом преподавания  для детей, студентов и взрослых более 10 лет.</p>
            </Col>
          </Row>

          <Row>
            <Col md="3"></Col>
            <Col md="4">
              <PsevdoButton txt='WhatsApp'/>
            </Col>
            <Col md="4">
              <PsevdoButton_Tlg txt='Telegram'/>
            </Col>
          </Row>

          <Row>
            <Col md="3">
            <img
                  variant="left"
                   width={250}
                  height={400} 
                  src={map} 
                  className="mr-3"
                />

            </Col>
            <Col md="9">
              <h4 style={{'text-align': "left", 'color': '#000'}}>Адрес: Амурская область, г.Благовещенск, ул. Калинина 142/6</h4>
              <h4 style={{'text-align': "left", 'color': '#c23416'}}><em>Посмотреть на карте</em></h4>
              <p style={{'text-align': "left", 'color': '#000', 'font-size': '14pt'}}><a href={hrefToGis}><strong>2ГИС</strong></a></p>
            </Col>
          </Row>

        </Container>
    );
  }
}

