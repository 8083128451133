import React, { Component } from 'react';
import { Col, Container} from 'react-bootstrap';
import styled from 'styled-components';

//background: #ffff80;
//border: solid dotted black;
//cursor: pointer;
//padding: 0px 0px;
//margin: 0px 0px;
//vertical-align: 10px;
//height: 30px;
//border-color: black;
//            color: red;
//background: #ff8306;
//background: #32f180;  color WA
const Styles = styled.div`
    .psevdobutton {
        font-size: 14pt;
        color: #2a2a2a;
        text-align: center;     
        display: inline-block;
        text-decoration: none;
        vertical-align: 5px;
        background: #fdd835;
        width: inherit;
        margin: 0px 0px;
        padding: 0px 0px;
        border: solid 1px;
        &:hover {
            background: #32f180;
        }
    }
`

export default class Head1 extends Component  {
    //constructor(props) {
    //    super(props)

        //this.onClickMyButton = this.onClickMyButton.bind(this)
    //}

    // disabled={this.props.dsbl}
    render () {
        return (
         
            <Styles>
            <Container fluid>
                <Col md="6">
                <p>Приглашаем на обучение основам конструирования и пошива одежды!</p>
                </Col>
            </Container>
            </Styles>
           
        )
    }
}