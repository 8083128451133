import React, { Component } from 'react';
import { Col, Container, Row, Card, Accordion } from 'react-bootstrap';
import sh from '../Img/Assets/as5_m.jpg';
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
    .imgsh {
        padding-top: 2rem;
        background: url(${sh}) no-repeat;
        height: 400px;
        background-size: cover;
        opacity: 0.3;
    }
    .descr {
        background: #ffffff;
        font-size: 14pt;
        color: #2a2a2a;
        padding-top: 2rem;
        padding-bottom: 10px;
        display: flex;
        justify-content: center;
    }
    .bt {
        background: #ffffff;
        font-size: 14pt;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;       
    }
`

//function App() {
export default class SignUp extends Component {
  render () {
    return (
      <>
      <Styles>
        <Container fluid className="imgsh">
                <h1 style={{'text-transform': 'uppercase', paddingTop: "8rem"}}>Записаться</h1>
        </Container>

        <Container fluid className="descr">
            <Col md="10">
                <h5><u><strong>Доброго времени суток. Благодарим Вас за выбор нашей школы! </strong></u></h5>
                <p>С нами вы быстрее научитесь шить красиво и качественно, чтобы изделие хорошо сидело на фигуре!</p>
                <p>Группы формируются не более, чем по 8 человек.</p>
                <h4>На наших занятиях комфортные условия для обучения:</h4>
                  <ul style={{'text-align': "left", 'line-height': '1.5', 'font-size': '14pt'}}>
                    <li>опытный профессиональный педагог;</li>
                    <li>светлое, просторное помещение;</li>
                    <li>необходимое швейное оборудование, инструменты;</li>
                    <li>удобный раскройный стол;</li>
                    <li>дружелюбная атмосфера;</li>
                    <li>готовность сотрудничать и экспериментировать! </li>
                  </ul>
                <h5 style={{'color': '#c23416'}}>С радостью ждем Вас по адресу: Амурская область, г.Благовещенск, ул. Калинина 142/6.</h5>
            </Col>
       </Container>

        <Container fluid className='bt'>
            <Col md="4">
                <PsevdoButton txt='Записаться на курсы' />
            </Col>
            <Col md="4">
                <PsevdoButton_Tlg txt='Записаться на курсы' />
            </Col>
        </Container>

        </Styles>
    </>
    )
    }
    }