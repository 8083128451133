import React, { Component } from 'react';
import { Col, Container, Row, Card, Accordion } from 'react-bootstrap';
import sh from '../Img/Assets/as3_m.jpg';
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
    .imgsh {
        padding-top: 2rem;
        background: url(${sh}) no-repeat;
        height: 400px;
        background-size: cover;
    }
    .heading1 {
      text-transform: uppercase;  
    }
    .txt1 {
      text-align: left;
      line-height: 1.5;
      font-size: 14pt;
    }
    .dur {
      text-align: left;
      font-size: 14pt;
    }
    .cost {
      color: #c23416;
    }
    .bt {
      background: #ffffff;
      font-size: 14pt;
      display: flex;
      justify-content: center;
      padding-bottom: 25px;       
  }
`

//function App() {
export default class Schedule extends Component {
  render () {
    return (
      <>
      <Styles>
        <Container fluid className="imgsh">
                <h1 style={{'text-transform': 'uppercase', paddingTop: "8rem"}}>Расписание</h1>
        </Container>

        <Container fluid style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          {/*<h2 style={{paddingTop: "2rem", paddingBottom: "2rem", 'text-transform': 'uppercase'}}>Перечень предлагаемых услуг</h2>*/}
          <Accordion fluid>

            <Accordion.Item fluid eventKey="0">
            <Accordion.Header>
            <h3 className='heading1'>Запись на Курс по конструированию одежды (Скоро начнётся)</h3>
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>вторник, четверг с 9 до 13 часов</h5>
                  <h5 className='cost'>Стоимость обучения: 7000 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="1">
            <Accordion.Header>
              <h3 className='heading1'>Курсы по основам конструирования и пошива одежды с нуля </h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>вторник, четверг - с 16-30 до 18 ч., суббота - с 15 до 18 часов</h5>
                  <h5 className='cost'>Стоимость обучения: 6500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="2">
            <Accordion.Header>
              <h3 className='heading1'>Курсы по основам конструирования и пошива одежды (скоро закончится)</h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>вторник, четверг с 18 до 21 часа</h5>
                  <h5 className='cost'>Стоимость обучения: 7000 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="3">
            <Accordion.Header>
              <h3 className='heading1'>Курсы по пошиву детской одежды</h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>вторник, четверг с 9 до 13 часов</h5>
                  <h5 className='cost'>Стоимость обучения: 6500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="4">
            <Accordion.Header>
              <h3 className='heading1'>Запись на курсы по построению и пошиву НИЖНЕГО БЕЛЬЯ (БЮСТГАЛЬТЕРОВ)</h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>сУББОТА  с 9 до 15 часов</h5>
                  <h5 className='cost'>Стоимость обучения: 7500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="5">
            <Accordion.Header>
              <h3 className='heading1'>Запись на курсы по построению и пошиву нижнего женского белья</h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>МОЖНО ПРИСОЕДИНИТЬСЯ ПО СУББОТАМ</h5>
                  <h5 className='cost'>Стоимость обучения: 7500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item fluid eventKey="6">
            <Accordion.Header>
              <h3 className='heading1'>МАСТЕР-классы по желанию</h3>              
              </Accordion.Header>
              <Accordion.Body>                 
                  <h5 className='dur'>в выходные или будние дни по договорённости</h5>
                  <h5 className='cost'>Стоимость обучения: уточнить</h5>
              </Accordion.Body>
            </Accordion.Item>

            </Accordion>
            </Container>

            <Container fluid className='bt'>
            <Col md="4">
                <PsevdoButton txt='Узнать подробней, записаться на курсы' />
            </Col>
            <Col md="4">
                <PsevdoButton_Tlg txt='Узнать подробней, записаться на курсы' />
            </Col>
            </Container>

      </Styles>

      </>
    )
  }
}