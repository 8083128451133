import React from "react"
import logo from '../img/logo_bsh.jpg'
import logo_wa from '../img/whatsapp-icon.svg'
import logo_te from '../img/telegram_logo.png'
import qr_tlg from '../img/qr_tlg.jpg'
import { Container, Row, Col } from "react-bootstrap"
import styled from 'styled-components';

//display: flex;
//justify-content: center;
const Styles = styled.div`
    .cont {
        background: #ffc400;
        font-size: 14pt;
        color: #2a2a2a;
        padding-top: 5px;
        padding-left: 10px;
        padding-bottom: 10px;  
        margin: -10px 0px;     
    }
    .row {
        display: flex;
        justify-content: flex-start;
    }
    .col {
        display: flex;
        justify-content: flex-end;
    }
    .col_ip {
        display: flex;
        justify-content: flex-end;
        font-size: 10pt;
        color: #6b6b6b;
    }
`

//const hrefMain = "https://www.будемшить.рф/"
const hrefMain = "/"
const hrefWA = "https://wa.me/79622932282"
const hrefTe = "https://t.me/Gorkovenkoyuliablag"    // <a href="https://tele.click/{username}">{username}</a>

class MyFooter extends React.Component {
    // img src="https://volgo-prime.ru/img/icons/whatsapp-icon.svg"
    // <a title="Whatsapp" href="whatsapp://send?phone=79622932282"><img src={logo_wa} alt="Whatsapp" /></a>
    // <Container fluid bg="warning" style={{backgroundColor: '#212145', color: '#fff', padding: '10px'}}>
    render () {
        return (
            <Styles>
            <Container fluid className="cont">
                <Row>               
                    <p><em>Будем шить для души, семьи и дела!</em></p>
                </Row>
                <Row className="row">
                    <Col md="1">
            {/* <footer className="myfooter"> */}
                        <a href={hrefMain}><img src={logo} alt="Будем Шить" height={'120px'} width={'120px'}/></a>
                    </Col>

                    <Col md="5">
                        <div className="footer_txt" style={{'line-height': '0.5'}}>
                            <p>&copy; <b>Школа шитья <big>"Будем шить"</big> </b></p>
                            <p style={{'line-height': '1'}}>Россия, 675000, город Благовещенск, ул. Калинина 142/6 </p>
                        </div>

                        <div class="messenger" style={{display: 'flex', 'margin-top': '-15px'}}>
                            <div id="messenger-links" class="messenger-links">                   
                                <a title="Whatsapp" href={hrefWA}><img src={logo_wa} alt="Whatsapp" /></a>
                                <a title="Telegramm" href={hrefTe}><img src={logo_te} alt="Telegramm" /></a>
                            </div>
                        </div>
                    </Col>

                    <Col md="5" className="col_ip">
                        <div className="footer_txt" style={{'line-height': '0.2'}}>
                            <p style={{'line-height': '0.1'}}>ИП: Горковенко Юлия Валерьевна </p>
                            <p style={{'line-height': '0.1'}}>ИНН: 280104956630 </p>
                            <p style={{'line-height': '0.1'}}>ОГРНИП: 321280100017859 </p>
                            <p style={{'line-height': '0.1'}}>тел: +79622932282 </p>
                            <p style={{'line-height': '0.1'}}>email: juliysha@mail.ru </p>
                        </div>
                    </Col>

                    <Col md="1" className="col">
                        <img src={qr_tlg} alt="Будем Шить" height={'170px'} width={'120px'}/>
                    </Col>

                    {/*<Col md="2" style={{paddingLeft: '100px'}}>
                    <img src={logo} alt="Будем Шить"height={'100px'} width={'100px'}/>
                    </Col>*/}

            {/* </footer> */}
                </Row>
            </Container>
            </Styles>
        )
    }
}

export default MyFooter
