import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import i1 from '../Img/Assets/as5_m.jpg'
import i2 from '../Img/Assets/as2_m.jpg'
import i3 from '../Img/Assets/as3_m.jpg'
import i4 from '../Img/Assets/as4_m.jpg'
import styled from 'styled-components';

const Styles = styled.div`
    .cont {
        padding-top: 0px;
        margin-top: -15px;
        background: #ffffff;
    }
`

//function App() {
export default class CarouselBox extends Component {
  render () {
    return (
      <>
        <Styles>
          {/* Comment */}
            <Carousel className='cont'>
              <Carousel.Item style={{'height':'500px', 'opacity': '0.3'}}>
                <img 
                  className='d-block w-100'
                  src={i1}
                  alt="i1"
                />
                <Carousel.Caption>
                  <h3>Курс по конструированию одежды</h3>
                  {/*<p>Описание картинки i1</p>*/}
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item style={{'height':'500px'}}>
                <img 
                  className='d-block w-100'
                  src={i2}
                  alt="i2"
                />
                <Carousel.Caption>
                  <h3>Курс обучению основам конструирования и пошива одежды с нуля</h3>
                  {/*<p>Описание картинки i2</p>*/}
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item style={{'height':'500px'}}>
                <img 
                  className='d-block w-100'
                  src={i3}
                  alt="i3"
                />
                <Carousel.Caption>
                  <h3>Курс по пошиву детской одежды</h3>
                  {/*<p>Описание картинки i3</p>*/}
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item style={{'height':'500px'}}>
                <img 
                  className='d-block w-100'
                  src={i4}
                  alt="i4"
                />
                <Carousel.Caption>
                  <h3>Мастер-классы по разным темам</h3>
                  {/*<p>Описание картинки i4</p>*/}
                </Carousel.Caption>
              </Carousel.Item>

            </Carousel>
            </Styles>
            </>
    );
  }
}

