import React, { Component } from 'react';
import { Card, Container, CardDeck, Button, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import CarouselBox from '../Components/CarouselBox';
import k1 from '../Img/Course/c1_1.jpg'
import k2 from '../Img/Course/c2_2.jpg'
import k3 from '../Img/Course/c3_3.jpg'
import mc4 from '../Img/MasterClass/mc4.jpg'
import MyFooter from './Test/components/MyFooter';
import styled from 'styled-components';


const Styles = styled.div`
  .row1 {
    display: flex;
    justify-content: space-around;
  }
  .row2 {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-around;
  }
  .col {
    display: flex;
    justify-content: space-around;
  }
`
//const navigate = useNavigate()
//const navigateCourse = () => {
//  console.log('onClick navigateCourse')
//  navigate('/course')
//}

//function App() {
export default class Home extends Component {
  //constructor() {
  //  super()
  //  this.state = {
  //    navigate : useNavigate(),
  //  }

  //  this.navigateCourse = this.navigateCourse.bind(this)
  //}

  //navigateCourse() {
  //  console.log('onClick navigateCourse')
  //  this.state.navigate('/course')
  //}

  render () {
    return (
      <>
      <Styles>
        <CarouselBox />

        <Container fluid style={{backgroundColor: '#ffffff'}}>
          {/*<h1 className='text-center m-4'>Курсы</h1>*/}
          <Row  className='row1'>
            <Col md="3" className='col'>
            <Card style={{'width':'24rem'}}>
              <Card.Img 
                /*style={{'height':'400px'}}*/
                variant='top'
                src={k1}
                /* width={140} */
                height={500} 
              />
              <Card.Body>
                <Card.Title>Курс по конструированию одежды</Card.Title>
                <Card.Text>
                  <u><em><strong>Что изучаем?</strong></em></u>
                  <ul style={{'text-align': 'left'}}>
                    <li>классификацию одежды, прибавки, объемы, силуэты одежды;</li>
                    <li>измерение фигуры, размер...</li>
                  </ul>
                </Card.Text>
                {/* <Button variant='primary' onClick={navigateCourse}>О курсе</Button> */}
                <Card.Link href="/course1">Подробнее</Card.Link>
              </Card.Body>
            </Card>
            </Col>

            <Col md="3" className='col'>
            <Card bg="warning" text="primary" style={{'width':'24rem'}}>
              <Card.Img
                variant='top'
                src={k2}
              />
              <Card.Body>
                <Card.Title>Курс обучению основам конструирования и пошива одежды с нуля</Card.Title>
                <Card.Text>
                  <u><em><strong>Что изучаем?</strong></em></u>
                  <ul style={{'text-align': 'left'}}>
                    <li>классификацию одежды, прибавки, объемы, силуэты одежды;</li>
                    <li>измерение фигуры, размер...</li>
                  </ul>
                </Card.Text>
                <Card.Link href="/course2">Подробнее</Card.Link>
              </Card.Body>
            </Card>
            </Col>

            <Col md="3" className='col'>
            <Card bg="warning" text="primary" style={{'width':'24rem'}}>
              <Card.Img
                variant='top'
                src={k3}
              />
              <Card.Body>
                <Card.Title>Курс по пошиву детской  одежды</Card.Title>
                <Card.Text>
                  <u><em><strong>Что шьём?</strong></em></u>
                  <ul style={{'text-align': 'left'}}>
                    <li>белье нательное;</li>
                    <li>свитшот, футболку из ...</li>
                  </ul>
                </Card.Text>
                <Card.Link href="/course3">Подробнее</Card.Link>
              </Card.Body>
            </Card>
            </Col>

            </Row>

          <Row className='row2'>
            <Col md="3" className='col'>
          {/*<h1 className='text-center m-4'>Дополнительные услуги</h1>*/}
            <Card bg="warning" text="primary" style={{'width':'24rem'}}>
              <Card.Img
                variant='top'
                src={mc4}
              />
              <Card.Body>
                {/*<Card.Title>Какие ещё услуги предлагаем:</Card.Title>*/}
                <Card.Title>Мастер-классы</Card.Title>
                <Card.Text>
                <u><em><strong>Мастер-классы по:</strong></em></u>
                  <ul style={{'text-align': 'left'}}>
                    <li>по построению и пошиву классических трусиков</li>
                    <li>по построению и пошиву трусиков "Браз...</li>
                  </ul>
                </Card.Text>
                <Card.Link href="/masterclasses">Подробнее</Card.Link>
              </Card.Body>
            </Card>
            </Col>

            <Col  md="3"></Col>
            <Col  md="3"></Col>
            </Row>


        </Container>
        {/*<MyFooter />*/}
        </Styles>
      </>
    );
  }
}

