import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

//background: #ffff80;
//border: solid dotted black;
//cursor: pointer;
//padding: 0px 0px;
//margin: 0px 0px;
//vertical-align: 10px;
//height: 30px;
//border-color: black;
//            color: red;
//background: #ff8306;
//background: #5fa2f0;  color Telegram
// background: #fdd835; yellow
const Styles = styled.div`
    .psevdobutton {
        font-size: 14pt;
        color: #2a2a2a;
        text-align: center;     
        display: inline-block;
        text-decoration: none;
        vertical-align: 5px;
        background: #5fa2f0;
        width: inherit;
        margin: 0px 0px;
        padding: 0px 0px;
        border: solid 1px;
        &:hover {
            background: #0080ff;
        }
    }
`

const hrefTe = "https://t.me/Gorkovenkoyuliablag" 
//const Te = () => {
//    return (
//        <strong><em> в Telegram</em></strong>
//    )
//}

export default class PsevdoButton_Tlg extends Component  {
    //constructor(props) {
    //    super(props)

        //this.onClickMyButton = this.onClickMyButton.bind(this)
    //}

    // disabled={this.props.dsbl}
    render () {
        return (
            <>
            <Styles>
            <Container>
                <a class="psevdobutton" href={hrefTe} target="_blank">
                   {this.props.txt}{/*{Te()}*/}
                </a>
            </Container>
            </Styles>
            </>
        )
    }
}