import React from "react"
//import Test1 from "../tests/Test1"
//import Test2 from "../tests/Test2"

class MyNav extends React.Component {
    //constructor(props) {
    //    super(props)
    //    this.state = {
    //        nameTest1 : "Тест 1",    // Наименование теста 1
    //        nameTest2 : "Тест 2",    // Наименование теста 2
    //        this.state  = {
                //arrteam : this.props.team
                //arrteam : ['Test1', 'Test2']
    //    }
    //}
    render () {
        // +" ("+arrq[0].q.length+")"
        //let [nameT1, arrq1] = Test1()
        //let [nameT2, arrq2] = Test2()

        // Нужно: 1- наименование тем; 2- id каждой темы; 3- количество вопросов по каждой теме
        var arrteam = this.props.team
        //this.setState({arrteam : this.props.team}, ()=>{this.setState({arrteam : [...this.props.team]})})
        //var mynav = document.getElementById("mynav")
        //var strnav = ''
        //arrteam.forEach(a => an.push(`<p><a className="mynavitem">${a}</a></p>`))
        //arrteam.forEach(a => {strnav += `<p><a className="mynavitem">${a}</a></p>`})
        //console.log(strnav)
        //mynav.innerHTML = strnav

        return (
            //<nav className="mynav">
            //    <p><a className="mynavitem" onClick={()=>this.props.onClickHR(1)}>{nameT1+" ("+arrq1.length+")"}</a></p>
            //    <p><a className="mynavitem" onClick={()=>this.props.onClickHR(2)}>{nameT2+" ("+arrq2.length+")"}</a></p>
            //</nav>
            
            /* <nav className="mynav"> */
            <div className="mynav">
                <p><a className="mynavitem" onClick={()=>this.props.onClickHR(0)}>В начало</a></p>
                {arrteam.map(a => (<p><a className="mynavitem" onClick={()=>this.props.onClickHR(a.id)}>{a.t+" (вопросов "+a.n+")"}{console.log(a)}</a></p>))}
            </div>
            /* </nav> */

            //<nav className="mynav">
            //    {arrteam.forEach(a => (<p><a className="mynavitem">{a}{console.log(`a: ${a}`)}</a></p>))}
            //</nav>

            //<nav className="mynav" id="mynav">
            //</nav>
            
        )
    }

    //onClickNav(id) {
    //    console.log(`Click nav, id: ${id}`)
    //}
}

export default MyNav