import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import c3_1 from '../Img/Course/c3_1.jpg'
import c3_2 from '../Img/Course/c3_2.jpg'
import c3_3 from '../Img/Course/c3_3.jpg'
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
  .bt {
    background: #ffffff;
    font-size: 14pt;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;       
  }
`


//function App() {
export default class Course3 extends Component {
  render () {
    return (
      <Styles>
        <Container style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          <Row>
            <Col md="2">
              <Row style={{paddingTop: "1rem"}}>

              <img
                  variant="left"
                   width={140}
                  height={230} 
                  src={c3_1} 
                  className="mr-3"
                />

              </Row>
              <Row style={{paddingTop: "2rem"}}>

                <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={140}
                  height={140} 
                  src={c3_2} 
                  className="mr-3"
                />

              </Row>

              <Row style={{paddingTop: "2rem"}}>

                <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={140}
                  height={270} 
                  src={c3_3} 
                  className="mr-3"
                />

              </Row>
                
            </Col>
            <Col md="9" style={{color: '#2a2a2a'}}>
              <h3><em>Скоро начало! (срок 6 месяцев)</em></h3>
                  <h2>Запись на Курс по пошиву детской  одежды</h2>
                  <h4><u><em><strong>Что шьём?</strong></em></u></h4>
                  <ul style={{'text-align': "left", 'line-height': '2', 'font-size': '15pt'}}>
                    <li>белье нательное;</li>
                    <li>свитшот, футболку из кулирной глади, футера;</li>
                    <li>платье для девочки с отрезной линией талии;</li>
                    <li>сорочку для мальчика;</li>
                    <li>шорты летние для девочки/мальчика;</li>
                    <li>брюки для девочки/мальчика;</li>
                    <li>юбку полусолнце с защипами и застежкой на потайную молнию;</li>
                    <li>куртку на подкладе с застежкой на замке "молния" и капюшоном</li>                    
                  </ul>
                  <h5>Количество готовых изделий: 8 - 10!</h5>
                  <h5><em>По окончанию курса выдается сертификат для вашего портфолио, подтверждающий обучение.</em></h5>
                  <h4><u><em><strong>Какие навыки приобретаются:</strong></em></u></h4>
                  <ul style={{'text-align': "left", 'line-height': '2', 'font-size': '15pt'}}>
                    <li>умение использовать готовые выкройки;</li>
                    <li>подгонять их по размеру ребенка;</li>
                    <li>строить основу плечевых, поясных изделий;</li>
                    <li>умение шить детскую одежду.</li>
                  </ul>
            </Col>
          </Row>
        </Container>

        <Container fluid className='bt'>
        <Col md="4">
            <PsevdoButton txt='Узнать подробней, записаться на курсы' />
        </Col>
        <Col md="4">
            <PsevdoButton_Tlg txt='Узнать подробней, записаться на курсы' />
        </Col>
      </Container>

      </Styles>

    );
  }
}

