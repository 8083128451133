import React, { Component } from 'react';
import { Col, Container, Row, Card, Accordion } from 'react-bootstrap';
import MyFooter from './Test/components/MyFooter';
//import Accordion from 'react-bootstrap/Accordion';
//import k1 from '../Img/k1.webp'
import c from '../Img/Assets/as5_m.jpg';
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
    .imgmc {
        padding-top: 2rem;
        background: url(${c}) no-repeat;
        height: 400px;
        background-size: cover;
        opacity: 0.3;
    }
    .heading1 {
      text-transform: uppercase;  
    }
    .txt1 {
      text-align: left;
      line-height: 1.5;
      font-size: 14pt;
    }
    .dur {
      text-align: left;
      font-size: 14pt;
    }
    .cost {
      color: #c23416;
    }
    .bt {
      background: #ffffff;
      font-size: 14pt;
      display: flex;
      justify-content: center;
      padding-bottom: 25px;       
  }
`

//function App() {
export default class Course extends Component {
  render () {
    return (
      <>
      <Styles>
        <Container fluid className="imgmc">
                <h1 style={{'text-transform': 'uppercase', paddingTop: "8rem"}}>Перечень предлагаемых услуг</h1>
        </Container>

        <Container fluid style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          {/*<h2 style={{paddingTop: "2rem", paddingBottom: "2rem", 'text-transform': 'uppercase'}}>Перечень предлагаемых услуг</h2>*/}
          <Accordion fluid>
            <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h3 className='heading1'>Курс по конструированию одежды</h3>              
              </Accordion.Header>
              <Accordion.Body>
              <h4><u><em><strong>Что изучаем?</strong></em></u></h4>
                  <ul className='txt1'>
                    <li>классификацию одежды, прибавки, объемы, силуэты одежды;</li>
                    <li>измерение фигуры, размер, полнотные группы, типы телосложений;</li>
                    <li>построение основы плечевого изделия с учетом индивидуальных особенностей и на типовую фигуру;</li>
                    <li>основы моделирования для получения футболки, халата. свитшота, платья, кардигана, блузы;</li>
                    <li>выполняем макеты изделий , примеряем, вносим изменения в макет, в конструкцию;</li>
                    <li>выполняем домашние задания по пошиву построенных изделий;</li>
                    <li>изучаем построение и выполняем макеты воротников, рукавов разной формы;</li>
                    <li>изучаем построение поясных изделий: брюк классической формы, кюлот, леггинсов;</li>
                    <li>изучаем построение и моделирование юбок различной формы.</li>
                    <li>По окончанию курса выдается для вашего портфолио сертификат, подтверждающий обучение.</li>
                  </ul>
                  <h5><em>По окончанию курса выдается для вашего портфолио сертификат, подтверждающий обучение.</em></h5>
                  <h5 className='dur'>Количество часов: 24 часа в месяц</h5>
                  <h5 className='cost'>Стоимость обучения: 7000 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
              <h3 className='heading1'>Курсы по основам конструирования и пошива одежды с нуля</h3>  
              </Accordion.Header>
              <Accordion.Body>
              <h4><u><em><strong>Что изучаем?</strong></em></u></h4>
                  <ul className='txt1'>
                    <li>классификацию одежды, силуэты, прибавки, объемы одежды;</li>
                    <li>измерение фигуры, размер, полнотные группы, типы телосложений;</li>
                    <li>построение основы плечевого изделия с учетом индивидуальных особенностей;</li>
                    <li>выполняем макет малого объёма полуприлегающего силуэта;</li>
                    <li>примеряем макет, вносим изменения в конструкцию;</li>
                    <li>моделируем основу для получения платья, кроим и отшиваем его.</li>
                    <li>Изучаем некоторые виды воротников и рукавов.</li>
                    <li>Строим основу плечевого изделия умеренного, большого объема, кроим и отшиваем изделия (футболку, толстовку, жакет).</li>
                    <li>Поясные изделия. Виды. Размерные признаки для поясных изделий.</li>
                    <li>Построение и пошив 1 вида юбки и брюк.</li>
                  </ul>
                  <h5>Количество готовых изделий: 8 - 10!</h5>
                  <h5><em>По окончанию курса выдается сертификат для вашего портфолио, подтверждающий обучение.</em></h5>
                  <h5 className='dur'>Количество часов: 24 часа в месяц, продолжительность - 6 месяцев</h5>
                  <h5 className='cost'>Стоимость обучения: 6500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>
              <h3 className='heading1'>Курсы по пошиву детской одежды</h3>  
              </Accordion.Header>
              <Accordion.Body>
              <h4><u><em><strong>Что шьём?</strong></em></u></h4>
                  <ul className='txt1'>
                    <li>белье нательное;</li>
                    <li>свитшот, футболку из кулирной глади, футера;</li>
                    <li>платье для девочки с отрезной линией талии;</li>
                    <li>сорочку для мальчика;</li>
                    <li>шорты летние для девочки/мальчика;</li>
                    <li>брюки для девочки/мальчика;</li>
                    <li>юбку полусолнце с защипами и застежкой на потайную молнию;</li>
                    <li>куртку на подкладе с застежкой на замке "молния" и капюшоном</li>                    
                  </ul>
                  <h5>Количество готовых изделий: 8 - 10!</h5>
                  <h5><em>По окончанию курса выдается сертификат для вашего портфолио, подтверждающий обучение.</em></h5>
                  <h4><u><em><strong>Какие навыки приобретаются:</strong></em></u></h4>
                  <ul className='txt1'>
                    <li>умение использовать готовые выкройки;</li>
                    <li>подгонять их по размеру ребенка;</li>
                    <li>строить основу плечевых, поясных изделий;</li>
                    <li>умение шить детскую одежду.</li>
                  </ul>
                  <h5 className='dur'>Количество часов: 24 часа в месяц, продолжительность - 4 месяца</h5>
                  <h5 className='cost'>Стоимость обучения: 6500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>
              <h3 className='heading1'>Курсы по построению и пошиву нижнего женского белья</h3>  
              </Accordion.Header>
              <Accordion.Body>
                  <h5 className='dur'>Количество часов: 24 часа в месяц, продолжительность - 3 месяца</h5>
                  <h5 className='cost'>Стоимость обучения: 7500 рублей в месяц</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>
              <h3 className='heading1'>Консультация по конструированию и пошиву одежды индивидуальная</h3>  
              </Accordion.Header>
              <Accordion.Body>
                  <h5 className='cost'>Стоимость консультации: 750 рублей в час</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
              <Accordion.Header>
              <h3 className='heading1'>Индивидуальное обучение по выбранной теме</h3>  
              </Accordion.Header>
              <Accordion.Body>
                  <h5 className='cost'>Стоимость обучения: 750 рублей в час</h5>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
              <Accordion.Header>
              <h3 className='heading1'>Мастер-классы по различным темам</h3>  
              </Accordion.Header>
              <Accordion.Body>                
                <h5 className='cost'>В зависимости от количества человек: 750 рублей в час</h5>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <h3 style={{paddingTop: "2rem"}}>Для учениц, начавших обучение, оговоренная цена сохраняется до конца курса без изменений</h3>
          
        </Container>

        <Container fluid className='bt'>
            <Col md="4">
                <PsevdoButton txt='Задать вопросы, записаться на курсы' />
            </Col>
            <Col md="4">
                <PsevdoButton_Tlg txt='Задать вопросы, записаться на курсы' />
            </Col>
        </Container>
        {/*<MyFooter />*/}
        </Styles>
        </>
    );
  }
}

