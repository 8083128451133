import React from "react"

class MyButtonF extends React.Component {

    render () {
        if (this.props.statusBtF === 0) {
            return (
                <div>
                    <button className="mybuttonfdisabled" disabled>Продолжить</button>
                </div>
        )}
        else {
            return (
                <div>
                    <button className="mybuttonf" onClick={()=>this.props.onClickBtF(1)}>Продолжить</button>
                </div>
            )
        }
    }

}

export default MyButtonF