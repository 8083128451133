import React from "react"

class MyLabel extends React.Component {
    render () {
        return (
            //<div className="mylabel">{this.props.ch}</div>
            //<plaintext className="mylabel">{this.props.ch}</plaintext>
            <button className="mylabel" disabled>{this.props.ch}</button>
        )
    }
}

export default MyLabel