import React, { Component } from 'react';
import { Col, Container, Row, Card } from 'react-bootstrap';
import c1_1 from '../Img/Course/c1_1.jpg'
import c1_2 from '../Img/Course/c1_2.jpg'
import c1_3 from '../Img/Course/c1_3.jpg'
import c1_4 from '../Img/Course/c1_4.jpg'
import c1_5 from '../Img/Course/c1_5.jpg'
import c1_6 from '../Img/Course/c1_6.jpg'
import styled from 'styled-components';
import PsevdoButton from '../Components/PsevdoButton';
import PsevdoButton_Tlg from '../Components/PsevdoButton_Tlg';

const Styles = styled.div`
  .bt {
    background: #ffffff;
    font-size: 14pt;
    display: flex;
    justify-content: center;
    padding-bottom: 25px;       
  }
`

const w = 270
const h = 400

//function App() {
//backgroundColor: '#a8ffa8'
export default class Course1 extends Component {
  render () {
    return (
      <Styles>
        <Container style={{paddingTop: "6rem", paddingBottom: "2rem", backgroundColor: '#ffffff'}}>
          <Row>
            <Col md="2">
              <Row>

              <img
                  variant="left"
                   width={50}
                  height={300} 
                  src={c1_1} 
                  className="mr-3"
                />

              </Row>
              <Row style={{paddingTop: "1rem"}}>

                <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={50}
                  height={300} 
                  src={c1_2} 
                  className="mr-3"
                />

              </Row>
                
            </Col>
            <Col md="9" style={{color: '#2a2a2a'}}>
              <h3><em>Скоро начало!</em></h3>
                  <h2>Запись на Курс по конструированию одежды</h2>
                  <h4><u><em><strong>Что изучаем?</strong></em></u></h4>
                  <ul style={{'text-align': "left", 'line-height': '2', 'font-size': '15pt'}}>
                    <li>классификацию одежды, прибавки, объемы, силуэты одежды;</li>
                    <li>измерение фигуры, размер, полнотные группы, типы телосложений;</li>
                    <li>построение основы плечевого изделия с учетом индивидуальных особенностей и на типовую фигуру;</li>
                    <li>основы моделирования для получения футболки, халата. свитшота, платья, кардигана, блузы;</li>
                    <li>выполняем макеты изделий , примеряем, вносим изменения в макет, в конструкцию;</li>
                    <li>выполняем домашние задания по пошиву построенных изделий;</li>
                    <li>изучаем построение и выполняем макеты воротников, рукавов разной формы;</li>
                    <li>изучаем построение поясных изделий: брюк классической формы, кюлот, леггинсов;</li>
                    <li>изучаем построение и моделирование юбок различной формы.</li>
                    <li>По окончанию курса выдается для вашего портфолио сертификат, подтверждающий обучение.</li>
                  </ul>
                  <h5><em>По окончанию курса выдается для вашего портфолио сертификат, подтверждающий обучение.</em></h5>
            </Col>
          </Row>
          <Container style={{padding: '20px', justifyContent: 'center', backgroundColor: '#ffffff'}}>
          <Row style={{paddingTop: "1rem"}}>
            <Col>
            <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={w}
                  height={h} 
                  src={c1_3} 
                  className="mr-3"
                />
            </Col>
            <Col>
            <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={w}
                  height={h} 
                  src={c1_4} 
                  className="mr-3"
                />
            </Col>
            <Col>
            <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={w}
                  height={h} 
                  src={c1_5} 
                  className="mr-3"
                />
            </Col>
            <Col>
            <img
                  /* paddingTop="2rem" */
                  variant="left"
                  width={w}
                  height={h} 
                  src={c1_6} 
                  className="mr-3"
                />
            </Col>
          </Row>
          </Container>

          <Container fluid className='bt'>
            <Col md="4">
                <PsevdoButton txt='Узнать подробней, записаться на курсы' />
            </Col>
            <Col md="4">
                <PsevdoButton_Tlg txt='Узнать подробней, записаться на курсы' />
            </Col>
        </Container>

        </Container>
        </Styles>
    );
  }
}

